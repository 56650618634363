<template>
  <Modal v-model="modifyPriceModal" :title="$t('key1005869')" :transfer="false" :mask-closable="false"
    width="550" @on-visible-change="modifyPriceModalChange">
    <Form class="table_box" ref="tableForm" :model="tableForm" @submit.native.prevent>
      <Table :loading="tableLoading" border max-height="550" :columns="modifyPriceColumns" :data="tableForm.modifyPriceData">
        <template #suggestPrice="{index}">
          <FormItem :prop="`modifyPriceData.${index}.suggestPrice`"
            :rules="{trigger: 'change', required: true, validator: validateSellingPrice()}">
            <InputNumber :max="999999999" :min="0" :formatter="(value) => formatterNumber(value, 2)"
              style="width: 100%; margin-top: 24px;" v-model="tableForm.modifyPriceData[index].suggestPrice"></InputNumber>
          </FormItem>
        </template>
      </Table>
    </Form>
    <template #footer>
      <Button @click="modifyPriceModal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="modifyPriceBtn">{{ $t('key1000443') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {formatterNumber, amountHandel, setConfigCharacters} from "@/utils/common";

export default {
  name: "modifyPriceModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      modifyPriceModal: false,
      modifyPriceColumns: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000315'),
          minWidth: 200,
          align: 'center',
          render: (h, params) => {
            let spec = '';
            let name = params.row.name;
            if (params.row.productGoodsSpecifications) {
              spec = params.row.productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、');
            }
            return h('div', {
              class: 'table-flex ptb10'
            }, [
              v.tableImg(h, params, null, params.row.imagePath),
              h('div', {
                class: 'text-align-left pl10'
              }, [
                h('p', {class: 'mb5 color-333'}, `${setConfigCharacters}SKU：` + params.row.ymsSku),
                (name) || h('p', {
                  attrs: {
                    title: name
                  },
                  class: 'text-over-line3 mb5 color-666'
                }, name),
                h('p', {
                  class: 'color-green'
                }, spec)
              ])
            ]);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000499'),
          renderHeader(h) {
            return h('p', {
              style: {
                whiteSpace: 'nowrap'
              }
            }, alias47916751af154eb5b47cd5de9d34633d.t('key1000499') + '(' + v.currencyState + ')');
          },
          key: 'suggestPrice',
          width: 175,
          align: 'center',
          slot: 'suggestPrice'
        },
      ],
      tableForm: {
        modifyPriceData: []
      }
    }
  },
  methods: {
    formatterNumber,
    // 获取修改价格列表数据
    getModifyPriceList(productId) {
      let v = this;
      v.axios.get(api.get_productGoods_priceDetails + `?productId=${productId}`, {loading: true}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            v.tableForm.modifyPriceData = data;
            v.$forceUpdate();
          }
          v.modifyPriceModal = true;
        }
      });
    },
    // 校验输入的销售价
    validateSellingPrice() {
      return function (rule, value, callback) {
        if (!value || value === 0) {
          return callback(new Error(alias47916751af154eb5b47cd5de9d34633d.t('key1005940')));
        } else {
          callback();
        }
      };
    },
    // 修改价格的确定按钮
    modifyPriceBtn() {
      let v = this;
      let newList = [];
      v.$refs['tableForm'].validate((valid) => {
        if (valid) {
          v.tableForm.modifyPriceData.map((item) => {
            newList.push({
              productGoodsId: item.productGoodsId,
              suggestPrice: amountHandel(item.suggestPrice)
            });
          });
          if (newList.length > 0) {
            v.axios.put(api.put_productGoods_batchUpdatePrice, newList, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
              if (response.data.code === 0) {
                v.modifyPriceModal = false;
                v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000085'));
                v.$emit('updateData', true);
              }
            });
          }
        }
      });
    },
    // 监听修改价格弹窗
    modifyPriceModalChange(val) {
      if (!val) {
        this.tableForm.modifyPriceData = [];
      }
    },
  }
}
</script>

<style scoped>

</style>