<template>
  <Modal v-model="alibabaProductModal" :title="$t('key1005860')" width="600" class-name="alibabaProductStyle"
    :transfer="false" :mask-closable="false" @on-visible-change="alibabaProductChange">
    <Form ref="alibabaProductForm" :model="alibabaProductForm" :rules="ruleValidate" :label-width="100">
      <Row style="width: 90%;" @submit.native.prevent>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005893')" prop="syncProductScope">
            <RadioGroup v-model="alibabaProductForm.syncProductScope">
              <Radio :label="0">{{ $t('key1004165') }}</Radio>
              <Radio :label="1">{{ $t('key1001019') }}</Radio>
            </RadioGroup>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005894')" prop="thirdAuthAccountId">
            <Select v-model="alibabaProductForm.thirdAuthAccountId" transfer filterable>
              <Option v-for="item in storesList" :key="item.thirdAuthAccountId" :value="item.thirdAuthAccountId">{{ item.code }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="alibabaProductForm.syncProductScope === 0">
          <Form-item :label="$t('key1005895')" prop="productInfoUrl">
            <Input
              @on-focus="inputFocus('productInfoUrl', alibabaProductForm.productInfoUrl)"
              type="textarea"
              :placeholder="$t('key1005896')"
              :autosize="{ minRows: 4, maxRows: 6}"
              v-model.trim="alibabaProductForm.productInfoUrl">
            </Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="alibabaProductForm.syncProductScope === 1">
          <Form-item :label="$t('key1000622')" prop="timeFrame">
            <Date-picker
              type="datetimerange"
              class="wid100P"
              @on-clear="resetTimer"
              @on-change="getTimer"
              :clearable="true"
              transfer
              format="yyyy-MM-dd HH:mm:ss"
              placement="bottom-end"
              :placeholder="$t('key1005897')"
              v-model="alibabaProductForm.timeFrame">
            </Date-picker>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="alibabaProductModal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="alibabaProductBtn">{{ $t('key1005898') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {commonCopyContent, setConfigCharacters} from "@/utils/common";

export default {
  name: "alibabaProductModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      alibabaProductModal: false,
      alibabaProductForm: {
        syncProductScope: 1, // 获取类型
        productInfoUrl: '', // 商品链接
        thirdAuthAccountId: null, // 店铺
        ymsPlatformAccountId: null,
        createdStartTime: null,
        createdEndTime: null,
        timeFrame: []
      },
      ruleValidate: {
        productInfoUrl: [
          {required: true, validator: v.validateLink(), trigger: 'blur'}
        ],
        timeFrame: [
          {
            required: true,
            trigger: 'change',
            type: 'array',
            message: alias47916751af154eb5b47cd5de9d34633d.t('key1005902'),
            fields: {
              0: {type: "date", required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1005902')},
              1: {type: "date", required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1005902')}
            }
          }
        ],
        thirdAuthAccountId: [
          {required: true, message: alias47916751af154eb5b47cd5de9d34633d.t('key1005903'), trigger: 'change'}
        ]
      },
      storesList: []
    }
  },
  methods: {
    // 获取焦点后自动清除输入框的表单校验状态
    inputFocus(propName, value) {
      let val = JSON.parse(JSON.stringify(value));
      this.$refs['alibabaProductForm'].fields.forEach((e) => {
        if (e.prop == propName) {
          e.resetField();
          this.alibabaProductForm.productInfoUrl = val;
        }
      })
    },
    // 初始化数据
    initFunAlibabaProduct() {
      let v = this;
      v.getAuthorizedStoresList().then((data) => {
        let list = data.list || [];
        v.storesList = list.filter((item) => {
          return item.status === 1;
        });
        if (list.length > 0) {
          v.initWebSocket();
          v.alibabaProductModal = true;
        } else {
          v.$Modal.confirm({
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1005904'),
            content: alias47916751af154eb5b47cd5de9d34633d.t('key1005905'),
            okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000321'),
            cancelText: alias47916751af154eb5b47cd5de9d34633d.t('key1000098'),
            onOk: () => {
              v.$router.push('/platformAuthorization');
            },
            onCancel: () => {
            }
          });
        }
      });
    },
    // 获取1668平台已经授权的平台店铺数据
    getAuthorizedStoresList() {
      let v = this;
      v.storesList = [];
      let query = {
        platformId: 'alibaba',
        code: '',
        name: '',
        invalidStartTime: null,
        invalidEndTime: null,
        platformAccountStatus: 1, // 1 已授权
        pageSize: 1000,
        pageNum: 1
      };
      return new Promise(resolve => {
        v.axios.post(api.post_ymsPlatformAccount_query, query, {loading: true}).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              resolve(data);
            }
          }
        });
      });
    },
    // 初始化 Websocket(实时获取同步1688平台商品的状态)
    initWebSocket() {
      let v = this;
      v.$webSocket.socket.onmessage = function (e) {
        v.$webSocket.heartReset().heartStart();
        let syncInfo = '';
        if (e) {
          try {
            syncInfo = JSON.parse(e.data);
          } catch (e) {
            syncInfo = null;
          }
          if (syncInfo) {
            if (syncInfo.type === 'sync') {
              let data = syncInfo.data;
              let errorCode = data.errorCode;
              // 0接口请求失败 1 部分匹配失败 2 全部匹配失败 3 匹配成功
              let errorCodeList = [
                {
                  errorCode: 0,
                  type: 'error',
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1005906'),
                  text1: alias47916751af154eb5b47cd5de9d34633d.t('key1004959') + data.message
                },
                {
                  errorCode: 1,
                  type: 'warning',
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1005907'),
                  optionsText: alias47916751af154eb5b47cd5de9d34633d.t('key1005908'),
                  text1: alias47916751af154eb5b47cd5de9d34633d.t('key1005909'),
                  text2: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005899')}`,
                  optionType: 'copy',
                  copyData: data.failSpuList ? data.failSpuList.join('、') : ''
                },
                {
                  errorCode: 2,
                  type: 'error',
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1005906'),
                  text1: `${alias47916751af154eb5b47cd5de9d34633d.t('key1005900')}${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005899')}`
                },
                {
                  errorCode: 3,
                  type: 'success',
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1005910'),
                  optionsText: alias47916751af154eb5b47cd5de9d34633d.t('key1005911'),
                  text1: alias47916751af154eb5b47cd5de9d34633d.t('key1005912'),
                  text2: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005899')}`,
                  optionType: 'click'
                },
                {
                  errorCode: 4,
                  type: 'error',
                  title: alias47916751af154eb5b47cd5de9d34633d.t('key1005906'),
                  text1: `${alias47916751af154eb5b47cd5de9d34633d.t('key1005901')}`
                },
              ];
              errorCodeList.map((item) => {
                if (item.errorCode === errorCode) {
                  v.asynchronousNotice(item);
                  // 同步1688平台商品成功，自动刷新商品列表数据
                  if (errorCode === 3) {
                    v.$emit('updateData', true);
                  }
                }
              })
            }
          }
        }
      };
    },

    // 获取按钮
    alibabaProductBtn() {
      let v = this;
      let query = {};
      let keyList = ['createdEndTime', 'createdStartTime', 'thirdAuthAccountId', 'syncProductScope'];
      for (let key in v.alibabaProductForm) {
        if (keyList.includes(key)) {
          query[key] = v.alibabaProductForm[key];
        }
      }
      query.productInfoUrl = v.sepCommasFn(v.alibabaProductForm.productInfoUrl, ['\n']);
      v.storesList.map((item) => {
        if (item.thirdAuthAccountId === v.alibabaProductForm.thirdAuthAccountId) {
          query.ymsPlatformAccountId = item.ymsPlatformAccountId;
        }
      })
      let validateField = [];
      if (v.alibabaProductForm.syncProductScope === 0) {
        validateField = ['productInfoUrl', 'thirdAuthAccountId'];
        query.createdEndTime = null;
        query.createdStartTime = null;
      } else {
        validateField = ['thirdAuthAccountId', 'timeFrame'];
        query.productInfoUrl = [];
      }
      Promise.all(validateField.map(field => {
        const promise = new Promise(function (resolve, reject) {
          v.$refs['alibabaProductForm'].validateField(field, (error) => {
            resolve(error)
          })
        })
        return promise
      })).then((data) => {
        if (data.every(i => !i)) {
          v.axios.post(api.post_productInfo_syncAlibabaProduct, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
            if (response.data.code === 0) {
              v.alibabaProductModal = false;
              v.$Message.info(alias47916751af154eb5b47cd5de9d34633d.t('key1005913'))
            }
          });
        }
      });
    },
    // 异步获取商品的notice通知提醒
    asynchronousNotice(data) {
      let v = this;
      v.$Notice[data.type]({
        name: 'alibabaProduct', // 标识
        title: data.title,
        duration: 10,
        render(h, params) {
          return h('div', {
            style: {
              display: 'flex',
              flexWrap: 'wrap',
              lineHeight: '18px',
            }
          }, [
            h('span', data.text1),
            data.optionsText ? h('Button', {
              props: {
                type: 'text'
              },
              style: {
                color: '#2D8CF0',
                display: 'inline-block',
                padding: 0,
                marginLeft: '3px',
                height: 0,
                lineHeight: '15px',
                border: 'none !important',
                boxShadow: 'initial !important',
                alignItems: 'baseline',
                fontSize: '14px'
              },
              on: {
                click: () => {
                  // 查看列表
                  if (data.optionType === 'click') {
                    let path = v.$route.path;
                    if (path === '/productCenter/productGoods') {
                      localStorage.setItem('1688ProductTalg', JSON.stringify(true));
                      setTimeout(() => {
                        v.$emit('updateData', '1688ProductTalg');
                      }, 500);
                    } else {
                      localStorage.setItem('1688ProductTalg', JSON.stringify(true));
                      setTimeout(() => {
                        v.$router.push('/productCenter/productGoods');
                      }, 500);
                    }
                  }
                  // 复制失败SPU
                  else {
                    if (data.copyData) {
                      commonCopyContent(data.copyData, alias47916751af154eb5b47cd5de9d34633d.t('key1000979'));
                    }
                  }
                  v.$Notice.close('alibabaProduct');
                  v.alibabaProductModal = false;
                  v.$emit('updateData', true);
                }
              }
            }, data.optionsText) : '',
            data.text2 ? h('span', data.text2) : '',
          ]);
        }
      });
    },
    // 选取时间范围
    getTimer(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000289'));
        v.resetTimer();
      } else {
        if (!value[0]) {
          v.alibabaProductForm.createdStartTime = null;
          v.alibabaProductForm.createdEndTime = null;
        } else {
          let timeList = v.defaultTimePeriod(value);
          v.alibabaProductForm.timeFrame = v.defaultTimePeriod(value, false);
          v.alibabaProductForm.createdStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.alibabaProductForm.createdEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 重置订单创建时间
    resetTimer() {
      this.alibabaProductForm.createdStartTime = null;
      this.alibabaProductForm.createdEndTime = null;
    },
    // 校验链接地址
    validateLink() {
      let v = this;
      let checkHttps = v.$regular.checkHttps;
      return function (rule, value, callback) {
        if (!value) {
          return callback(new Error(alias47916751af154eb5b47cd5de9d34633d.t('key1005914')));
        } else if (!checkHttps.test(value)) {
          return callback(new Error(alias47916751af154eb5b47cd5de9d34633d.t('key1005915')));
        } else {
          callback();
        }
      };
    },
    // 监听弹窗
    alibabaProductChange(value) {
      if (!value) {
        this.$refs['alibabaProductForm'].resetFields();
      }
    }
  },
  watch: {
    'alibabaProductForm.syncProductScope': {
      handler(value) {
        let fieldlist = this.$refs['alibabaProductForm'].fields;
        let keyList = [];
        if (value === 1) {
          keyList = ['productInfoUrl', 'thirdAuthAccountId'];
        } else {
          keyList = ['timeFrame', 'thirdAuthAccountId'];
        }
        fieldlist.map((item, index) => {
          if (keyList.includes(item.prop)) {
            item.resetField();
            this.$forceUpdate();
          }
        });
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .alibabaProductStyle {
  .ivu-form-item {
    .ivu-form-item-label {
      font-size: 14px;
    }

    .ivu-radio-group {
      position: relative;
      top: -1px;
    }
  }
}
</style>