<template>
  <Modal v-model="importCommodityInfoModal" :title="$t('key1005870')" :transfer="false" :mask-closable="false" width="600"
    @on-visible-change="importCommodityInfoChange">
    <Form ref="importCommodityInfo" :label-width="130" @submit.native.prevent>
      <Row type="flex">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" style="margin-bottom: 24px;">
          <h2 class="font-weight-bold">{{ $t('key1005883') }}</h2>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005881')" prop="ymsProductCategoryId" class="required_style_label">
            <classificationSelect class="width_350" ref="classificationDom" @selectValue="changeValue"></classificationSelect>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1005882')">
            <Button type="text" @click="download" :disabled="!ymsProductCategoryId" class="download_styles">{{ $t('key1000251') }}</Button>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" style="margin-bottom: 24px;">
          <h2 class="font-weight-bold">{{ $t('key1005884') }}</h2>
        </Col>
        <Col span="24" class="no_margin_style">
          <Form-item :label="$t('key1000247')" prop="file" class="required_style_label">
            <Upload
              style="display: inline-block"
              :headers="headObj"
              ref="uploadRef"
              name='files'
              :show-upload-list="false"
              :action="uploadAction"
              :data="parameter"
              :before-upload="beforeHandleUpload"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :on-exceeded-size="uploadExceedSize"
              :on-format-error="handleFormatError"
              :max-size="uploadFilesMaxSize"
              :format="['zip']">
              <Button class="mr10">
                <i class="iconfont mr5">&#xe663;</i>
                {{ $t('key1000250') }}
              </Button>
            </Upload>
          </Form-item>
        </Col>
        <Col push="3" :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="excleFile">
          <h3 class="excleFile_title">{{ excleFile.name }}</h3>
        </Col>
        <Col push="3" :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <div class="flex align-items-center">
            <span>{{ $t('key1005885') }}</span>
            <RadioGroup v-model="updateIgnore">
              <Radio :label="0">{{ $t('key1000254') }}</Radio>
              <Radio :label="1">{{ $t('key1000252') }}</Radio>
            </RadioGroup>
          </div>
        </Col>
        <Col push="3" :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <div class="tips_styles">
            {{ $t('key1002343') }}<br/>
            {{ $t('key1005886') }}<br/>
            {{ $t('key1005887') }}<br/>
            {{ $t('key1005888') }}<br/>
            {{ $t('key1005864') }}<br/>
            {{ $t('key1005889') }}<br/>
          </div>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button type="primary" @click="saveImport">{{ $t('key1000097') }}</Button>
      <Button @click="importCommodityInfoModal = false">{{ $t('key1000098') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import classificationSelect from "@/components/common/classificationSelect.vue";

export default {
  name: "importCommodityInfoModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ymsProductCategoryId: null,
      importCommodityInfoModal: false,
      uploadAction: api.post_productInfo_excelImport,
      excleFile: null,
      updateIgnore: 0,
      parameter: {
        ymsProductCategoryId: v.ymsProductCategoryId,
        updateIgnore: v.updateIgnore
      },
      uploadFilesMaxSize: 512000, // 上传文件大小上线（kb）
      ymsProductCategoryList: [],
    }
  },
  methods: {
    // 获取云卖分类的数据
    getCloudSalesClassification() {
      if (this.ymsProductCategoryList.length <= 0) {
        this.getCloudTreeData(api.post_ymsProductCategory_api_queryAll, 'tile').then((data) => {
          this.ymsProductCategoryList = data;
          this.$nextTick(() => {
            this.$refs['classificationDom'].initClassificationData(data);
          });
        });
      }
    },
    // 分类
    changeValue(value) {
      this.ymsProductCategoryId = value;
    },
    // 上传文件之前
    beforeHandleUpload(excleFile) {
      this.excleFile = excleFile;
      return false;
    },
    // 上传文件失败
    uploadError() {
      this.importCommodityInfoModal = false;
      this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000259'));
      return false;
    },
    // 控制上传图片大小
    uploadExceedSize() {
      this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1005890'));
      return false;
    },
    // 上传文件成功
    uploadSuccess(res, file, fileList) {
      let v = this;
      if (res.code === 0) {
        v.importCommodityInfoModal = false;
        v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000261'));
        setTimeout(() => {
          v.$emit('updateData', true);
        }, 2000);
      }
    },
    // 关闭弹窗重置数据
    reset() {
      this.ymsProductCategoryId = null;
      this.excleFile = null;
      this.updateIgnore = 0;
    },
    // 导入的确定按钮
    saveImport() {
      let v = this;
      v.importCommodityInfoModal = true;
      if (v.ymsProductCategoryId) {
        if (v.excleFile) {
          v.parameter.ymsProductCategoryId = v.ymsProductCategoryId;
          v.parameter.updateIgnore = v.updateIgnore;
          v.$refs.uploadRef.post(this.excleFile); // 手动上传
          setTimeout(() => {
            v.excleFile = null;
            v.importCommodityInfoModal = false;
          }, 2000);
        } else {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000269'));
          return false;
        }
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005891'));
        return false;
      }
    },
    // 模板下载
    download() {
      let v = this;
      if (v.ymsProductCategoryId) {
        v.axios.get(api.get_productInfo_getImportExcelTemplate + v.ymsProductCategoryId).then((res) => {
          if (res.data.code === 0) {
            let url = res.data.datas;
            if (url) {
              window.open(url, '_self');
              setTimeout(() => {
                v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1005892'));
              }, 1500)
            }
          }
        })
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1005891'));
        return false;
      }
    },

    // 监听弹窗
    importCommodityInfoChange(value) {
      if (value) {
        this.getCloudSalesClassification();
      } else {
        this.reset();
        this.$refs['classificationDom'].reset();
      }
    }
  },
  components: {
    classificationSelect
  }
}
</script>

<style lang="less" scoped>
.excleFile_title {
  margin-bottom: 24px;
  font-weight: bold;
  color: #333;
}

.tips_styles {
  color: #9a9797;
  margin: 12px 0 20px 0;
}

.download_styles {
  color: #57a3f3;
  font-size: 13px;
  padding: 0;
  position: relative;
  top: -1px;
  border: none !important;
}

.ivu-btn-text:focus {
  box-shadow: initial !important;
}

.ivu-btn-text[disabled] {
  color: #c5c8ce !important;
}
</style>
